module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://c2d02c49f6bd4f8d9ed7d433dedc1f40@o553932.ingest.sentry.io/5681844","environment":"production","enabled":false},
    },{
      plugin: require('../plugins/segment/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"5snygedwXuQcdHuXW8jdOEVRqUiQelKa","trackPage":false,"ccpaLibURL":"https://calstate.aaa.com/privacy-rights/v1/lib","ccpaApiURL":"https://calstate.aaa.com/endpoints/privacy-rights/v1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"disableBgImage":true,"maxWidth":2880}},{"resolve":"gatsby-remark-copy-linked-files","options":{}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
